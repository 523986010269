const BASE_URL = process.env.REACT_APP_YOUTUBE_BACKEND_URL
const BASE_URL_FOR_MORE = process.env.REACT_APP_YOUTUBE_BACKEND_URL_FOR_MORE
const API_KEY = process.env.REACT_APP_YOUTUBE_API_KEY

const backend_urls = {
 trendingVideos: `${BASE_URL}?regionCode=US&key=${API_KEY}&maxResults=15&part=snippet&chart=mostPopular`,
 tags: (id)=>`${BASE_URL}?part=snippet,contentDetails&id=${id}&key=${API_KEY}`,
 more: (id)=>`${BASE_URL_FOR_MORE}?key=${API_KEY}&id=${id}&part=brandingSettings`
};

export default backend_urls