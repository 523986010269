import Grid from '@mui/material/Grid2';
import { Typography, Button } from '@mui/material';
import Logo from './assets/logo.png';

const Navbar = () => {

    return (
        <Grid container sx={{ backgroundColor: 'rgba(0,0,0,0.6)', padding: '10px' }} justifyContent='space-between' alignItems='center'>
            <Grid item>
                <Grid container alignItems='center' sx={{ gap: '10px' }}>
                    <img src={Logo} alt='tagsbuddy' width='80px' height='50px' />
                    <Typography sx={{ color: 'white' }}>
                        Tags Buddy
                    </Typography>
                </Grid>
            </Grid>
            {/* <Grid item>
                <Grid container alignItems='center'>
                    <Button variant='contained' sx={{ marginRight: '10px' }} color='success'>Signup</Button>
                    <Button variant='contained'>Login</Button>
                </Grid>
            </Grid> */}
        </Grid>
    );
}
export default Navbar;