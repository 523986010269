import Navbar from "../../components/Navabar/Navbar";
import Grid from '@mui/material/Grid2';
import { TextField, Button } from '@mui/material';
import { useEffect, useState } from "react";
import { Container, Box, Typography, Card, CardMedia, CardContent } from '@mui/material';
import axios from 'axios';
import React from 'react';
import backend_urls from "../../common/backend_urls";
import { useNavigate } from 'react-router-dom';
import {toast} from 'react-hot-toast';

const Home = () => {

    const [trendingVideos, setTrendingVideos] = useState([]);
    const [trendingLoader, setTrendingLoader] = useState(false);
    const [search, setSearch] = useState('');
    const navigate = useNavigate();

    const fetchTrendingList = async () => {
        setTrendingLoader(true);
        try {
            const response = await axios.get(backend_urls.trendingVideos);
            setTrendingVideos(response.data?.items ?? []);
        }
        catch (error) {
            console.log(error);
            setTrendingVideos([]);
        }
        finally {
            setTrendingLoader(false);
        }
    }

    useEffect(() => {
        fetchTrendingList();
    }, [])

    const searchHandler = async () => {
        const pattern = /(?<=watch\?v=|\/videos\/|embed\/|youtu\.be\/|shorts\/|\/v\/|\/e\/|watch\?v%3D|watch\?feature=player_embedded&v=|%2Fvideos%2F|embed%2F|youtu\.be%2F|%2Fv%2F)[^#&?\n]*/;
        try {
            const result = search.match(pattern);
            console.log(result)
            if (result) {
                 navigate(`/search/${result[0]}/`);
            }
            else{
            toast.error('Wrong Url! Please Retry.')
            }
        } catch (error) {
            console.error("Invalid regex: ", error.message);
            toast.error('Please Retry', error.message);
        }
    }

    return (
        <>
            <Navbar />
            <Container maxWidth="sm" style={{ fontFamily: 'Arial, sans-serif', padding: '20px', backgroundColor: '#f0f0f0' }}>
                {/* Header with search bar */}
                <Box display="flex" justifyContent="center" alignItems="center" mb={3}>
                    <TextField
                        variant="outlined"
                        placeholder="Enter video URL"
                        style={{ marginRight: '10px', flexGrow: '1' }}
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                    <Button variant="contained" color="primary" disabled={!(search.length > 0)} onClick={searchHandler}>
                        Search
                    </Button>
                </Box>

                {/* Buttons */}
                <Box display="flex" justifyContent="center" mb={3}>
                    <Button variant="contained" color="secondary" onClick={() => window.open('https://youtube.com/')}>
                        Open YouTube
                    </Button>
                </Box>

                {/* Trending tags section */}
                <Box textAlign="center" mb={3}>
                    <Button variant="contained" color="warning" fullWidth style={{ marginBottom: '10px' }}>
                        Get Top Tags of Today
                    </Button>
                </Box>

                {/* Video Thumbnails */}
                <Grid>
                    <Typography variant="h6" mb="15px" textAlign='center'>See tags for the trending videos of the day</Typography>
                    {
                        trendingLoader ?
                            <Typography>Loading ... </Typography>
                            :
                            trendingVideos?.length > 0 ?
                                <Grid container sx={{ gap: '10px' }} flexDirection='column' alignItems='center'>
                                    {
                                        trendingVideos?.map(item =>
                                            <Card key={item?.id} sx={{ width: '100%', maxWidth: '500px' }} onClick={() => navigate(`/search/${item?.id}/`)}>
                                                <CardMedia
                                                    component="img"
                                                    sx={{ height: "140px", width: "100%" }}
                                                    image={item?.snippet?.thumbnails?.medium?.url} // Replace with your video thumbnail
                                                    alt={item?.snippet?.title}
                                                />
                                                <CardContent sx={{ width: '100%' }}>
                                                    <Typography variant="body2" color="textSecondary">
                                                        {item?.snippet?.title}
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                        )}
                                </Grid>
                                :
                                <Typography> No data found... </Typography>
                    }
                </Grid>
            </Container>
        </>
    );
}
export default Home;