import Search from './pages/Search/Search';
import Home from './pages/Home/Home';
import {Routes,Route} from 'react-router-dom';

function App() {
  return (
    <>
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/search/:searchId/' element={<Search />} />
    </Routes>
    </>
  );
}

export default App;
