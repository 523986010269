import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import axios from 'axios';
import backend_urls from "../../common/backend_urls";
import { Typography, Tab, Box } from '@mui/material';
import Grid from '@mui/material/Grid2';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

const Search = () => {

    const [data, setData] = useState([]);
    const [tabs, setTabs] = useState(['Tags', 'Thumbnails', 'More']);
    const [currentTab, setCurrentTab] = useState("0");
    const [title, setTitle] = useState('');
    const [loader, setLoader] = useState(false);
    const { searchId } = useParams();

    const fetchData = async () => {
        setLoader(true);
        try {
            const response = await axios.get(backend_urls.tags(searchId));
            const demoData = []
            demoData[0] = response.data?.items[0]?.snippet?.tags;
            demoData[1] = response.data?.items[0]?.snippet?.thumbnails;
            setTitle(response.data?.items[0]?.snippet?.title);
            const responseForMore = await axios.get(backend_urls.more(response.data?.items[0]?.snippet?.channelId ?? null));
            demoData[2] = responseForMore?.data?.items[0]?.brandingSettings?.channel;
            setData(demoData);
        }
        catch (error) {
            setData([]);
            setTitle('');
        }
        finally {
            setLoader(false);
        }
    }

    useEffect(() => {
        fetchData();
    }, [])

    const formateTagsHandler = (tagsString) => {
        const pattern = /[^\s"']+|"([^"]*)"|'([^']*)'/g;
        const result = tagsString.match(pattern).map(match => match.replace(/(^["']|["']$)/g, ''))
        if (result) {
            return result;
        }
        else {
            return [];
        }
    }

    return (
        <>
            {
                loader
                    ?
                    <Typography sx={{ textAlign: 'center' }}>Loading....</Typography>
                    :
                    data?.length == 0
                        ?
                        <Typography sx={{ textAlign: 'center' }}>No Data Found...</Typography>
                        :
                        <TabContext value={currentTab}>
                            <Grid container>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
                                    <TabList onChange={(e, value) => setCurrentTab(value)} aria-label="lab API tabs example">
                                        {tabs?.map((item, index) => <Tab label={item} value={index.toString()} key={index} />)}
                                    </TabList>
                                </Box>
                            </Grid>
                            <TabPanel value="0">
                                {
                                    !data[0].length > 0
                                        ?
                                        <Typography>No data Found...</Typography>
                                        :
                                        <Grid container flexDirection='column'>
                                            {
                                                data[0].map((item, index) => <Grid item key={index}>
                                                    {item}
                                                </Grid>)
                                            }
                                        </Grid>
                                }
                            </TabPanel>
                            <TabPanel value="1">
                                {
                                    !data[1]
                                        ?
                                        <Typography>No data Found...</Typography>
                                        :
                                        <Grid container flexDirection='column'>
                                            <Grid item>
                                                <img src={data[1]?.medium?.url} alt="thumbnail" width={data[1]?.medium?.width} height={data[1]?.medium?.height} />
                                                <Typography>{title}</Typography>
                                            </Grid>
                                            {
                                                Object.entries(data[1]).map((item, index) => <Grid item key={index}>
                                                    {`${item[1]?.width} * ${item[1]?.height}`}
                                                </Grid>)
                                            }
                                        </Grid>
                                }
                            </TabPanel>
                            <TabPanel value="2">
                                {
                                    !data[2]
                                        ?
                                        <Typography>No data Found...</Typography>
                                        :
                                        <Grid container flexDirection='column'>
                                            {
                                                data[2]?.title
                                                && <Grid item>
                                                    <Typography>
                                                        Ttitle
                                                    </Typography>
                                                    {data[2].title}
                                                </Grid>
                                            }
                                            {
                                                data[2]?.description
                                                && <Grid item>
                                                    <Typography>
                                                        Description
                                                    </Typography>
                                                    {data[2].description}
                                                </Grid>
                                            }
                                            {
                                                data[2]?.keywords &&
                                                <Grid item>
                                                    <Typography>
                                                        Tags
                                                    </Typography>
                                                    {
                                                        !formateTagsHandler(data[2]?.keywords)
                                                            ?
                                                            <Typography>No Tags Found</Typography>
                                                            :
                                                            <Grid container flexDirection='column'>
                                                                {
                                                                    formateTagsHandler(data[2]?.keywords).map((item, index) => <Grid item key={index}>
                                                                        {item}
                                                                    </Grid>)
                                                                }
                                                            </Grid>
                                                    }
                                                </Grid>
                                            }
                                        </Grid>
                                }
                            </TabPanel>
                        </TabContext>
            }
        </>
    );
}
export default Search;